<template>
  <div id="app" class="container-md" v-cloak>
    <transition name="fade">
      <div v-if="answered">
        <div class="d-flex flex-column mx-auto" id="content">
          <div id="title" class="p-4">
            <img
              v-if="danish"
              class="float-right position-relative click"
              style="max-width: 10%"
              @click="
                menu.completed.pop();
                triggerBreakModal();
              "
              src="grafik/logo_main_da.png"
              alt="tilbage til modulvalg"
            />
            <img
              v-else
              class="float-right position-relative click"
              style="max-width: 10%"
              @click="
                menu.completed.pop();
                triggerBreakModal();
              "
              src="grafik/logo_main_en.png"
              alt="back to selection"
            />
            <div>
              <span v-for="i in currentPage" v-bind:key="i">
                <a
                  href="#"
                  class="c-nav"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="strippedTitle(modul[i].titel)"
                  tabindex="0"
                  @click="specificPage(i)"
                >
                  <i class="fas fa-circle mx-1 blue-icon"></i>
                </a>
              </span>
              <span v-for="i in modul.length - currentPage - 1" v-bind:key="i">
                <a
                  href="#"
                  class="c-nav"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="strippedTitle(modul[currentPage + i].titel)"
                  tabindex="0"
                  @click="specificPage(currentPage + i)"
                >
                  <i class="far fa-circle mx-1 blue-icon"></i>
                </a>
              </span>
            </div>
            <h1 class="pt-2" v-html="modul[currentPage].titel"></h1>
          </div>
          <div v-if="modul[currentPage].intro">
            <div class="svarboks">
              <div class="svarboksTekst" v-html="modul[currentPage].text"></div>
            </div>
          </div>
          <div v-else>
            <div id="imageHeader" class="d-flex justify-content-center">
              <div
                style="position: relative"
                v-if="
                  modul[currentPage].video && modul[currentPage].noVideo != svar
                "
                class="d-flex justify-content-center imageVideoHeader w-75"
                v-on:click="videoClicked($event.target)"
              >
                <!-- <div v-if="modul[currentPage].type=='youtube'" class="embed-responsive embed-responsive-16by9"> -->
                <!-- <iframe class="embed-responsive-item" v-bind:src="modul[currentPage].video"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> -->

                <div
                  v-if="modul[currentPage].type == 'youtube'"
                  v-on:click="youtubePlay"
                  class="embed-responsive embed-responsive-16by9 youtubeVideo"
                  v-bind:style="{
                    backgroundImage: 'url(' + youtubePosterImage + ')',
                    backgroundSize: 'contain',
                  }"
                >
                  <iframe
                    id="video"
                    style="display: none"
                    width="650"
                    height="366"
                    v-bind:src="modul[currentPage].video"
                    rel=" 0"
                    enablejsapi="1"
                    modestbranding="0"
                    controls="0"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <!-- </div> -->
                </div>
                <video
                  v-else-if="modul[currentPage].videoSeparat"
                  :poster="modul[currentPage].posterSeparat[svar]"
                  preload="none"
                  class="w-100"
                >
                  <source
                    :src="modul[currentPage].videoSeparat[svar]"
                    type="video/mp4"
                  />
                  <track
                    v-if="danish"
                    :src="modul[currentPage].trackSeparat[svar]"
                    kind="subtitles"
                    srclang="da"
                    label="Danish"
                    default
                  />
                  <track
                    v-else
                    :src="modul[currentPage].trackSeparat[svar]"
                    kind="subtitles"
                    srclang="en"
                    label="English"
                    default
                  />
                </video>
                <video
                  v-else-if="!modul[currentPage].videoSeparat"
                  :poster="modul[currentPage].poster"
                  preload="none"
                  class="w-100"
                >
                  <source :src="modul[currentPage].video" type="video/mp4" />
                  <track
                    v-if="danish"
                    :src="modul[currentPage].track"
                    kind="subtitles"
                    srclang="da"
                    label="Danish"
                    default
                  />
                  <track
                    v-else
                    :src="modul[currentPage].track"
                    kind="subtitles"
                    srclang="en"
                    label="English"
                    default
                  />
                </video>
                <i
                  v-if="videoPlayButton"
                  class="fas fa-play-circle video-play-button fa-4x"
                  aria-hidden="true"
                ></i>
              </div>
              <a
                v-else-if="modul[currentPage].imageLink"
                v-bind:href="modul[currentPage].imageLink"
                class="w-50 h-100"
                target="_blank"
                download
                ><img :src="modul[currentPage].image" class="w-100"
              /></a>
              <img v-else :src="modul[currentPage].image" class="w-50 h-100" />
            </div>
            <div class="d-flex flex-column svarboks p-md-5">
              <!-- pl-4 pr-4 pb-4 -->
              <div>
                <i
                  v-if="modul[currentPage].audio"
                  id="audioButton"
                  class="
                    faButton
                    fas
                    fa-pause-circle fa-3x
                    mr-4
                    position-absolute
                    rounded-circle
                  "
                  @click="toggleAudio()"
                ></i>
                <div
                  v-if="
                    (modul[currentPage].tipsTekst &&
                      modul[currentPage].noTips != svar) ||
                    modul[currentPage].altTips == svar
                  "
                >
                  <div
                    v-bind:id="danish ? 'textContainer' : 'textContainer_en'"
                    class="svarboksTekst d-flex flex-column"
                  >
                    <p
                      v-if="modul[currentPage].videoTekst"
                      class="videoTekst marginAdjustLeft"
                    >
                      <i>Video:</i>
                      {{ modul[currentPage].videoTekst }}
                    </p>

                    <div
                      class="
                        float-left
                        d-flex
                        align-items-baseline
                        blue-icon
                        pr-2
                      "
                    >
                      <span
                        v-if="danish"
                        class="tipsAnbefalinger h4 marginAdjustLeft pt-4"
                        >Tips</span
                      >
                      <span
                        v-else
                        class="tipsAnbefalinger h4 marginAdjustLeft pt-4"
                        >Tips</span
                      >
                    </div>
                    <div id="tips" class="collapse svarboks">
                      <div class="d-flex flex-column p-3">
                        <div
                          v-if="modul[currentPage].altTips == svar"
                          v-html="modul[currentPage].tipsTekstAlt"
                        ></div>
                        <div v-else v-html="modul[currentPage].tipsTekst"></div>
                        <div
                          v-if="
                            modul[currentPage].tips &&
                            modul[currentPage].noTips != svar
                          "
                          class="bg-light"
                        >
                          <ul class="nav nav-tabs">
                            <span
                              v-for="(tip, index) in modul[currentPage].tips"
                              v-bind:key="index"
                            >
                              <li>
                                <a
                                  class="nav-link"
                                  href="javascript:void(0)"
                                  @click="changeTip(index)"
                                  :class="{ active: index == selectedTip }"
                                  >{{ tip.titel }}</a
                                >
                              </li>
                            </span>
                          </ul>
                          <div v-html="tips" class="p-md-4"></div>
                        </div>
                      </div>
                    </div>
                    <a
                      role="button"
                      class="h6 blue-icon collapsed align-self-end"
                      data-toggle="collapse"
                      href="#tips"
                      aria-expanded="false"
                      aria-controls="tips"
                      :class="{ 'd-none': !menu.seMere }"
                      @click="menu.seMere = !menu.seMere"
                    >
                      <span v-if="danish">... SE MERE</span>
                      <span v-else>... READ MORE</span>
                    </a>
                  </div>
                </div>

                <!-- Quiz -->
                <div
                  v-if="
                    modul[currentPage].quiz && modul[currentPage].noQuiz != svar
                  "
                >
                  <div v-if="danish" class="svarboksTekst d-flex flex-column">
                    <div
                      class="
                        float-left
                        click
                        d-flex
                        align-items-baseline
                        blue-icon
                        pr-2
                      "
                      data-toggle="collapse"
                      href="#quiz"
                      aria-expanded="false"
                      aria-controls="quiz"
                      @click="quiz.collapsed = !quiz.collapsed"
                    >
                      <i
                        class="fas mx-2 fa-2x blue-icon"
                        :class="{
                          'fa-plus': quiz.collapsed,
                          'fa-minus': !quiz.collapsed,
                        }"
                      ></i>
                      <p class="h4">Quiz</p>
                    </div>
                    <div id="quiz" class="collapse">
                      <p
                        class="p-md-2 flex-column"
                        v-html="modul[currentPage].quiz[quiz.quizIndex].quizSpg"
                      ></p>
                      <div class="quiz position-relative">
                        <transition name="fade">
                          <div
                            v-show="this.quizBesvaret"
                            class="p-4 overlay position-absolute"
                            :class="{
                              'bg-success': quiz.korrekt,
                              error: !quiz.korrekt,
                            }"
                          >
                            <span class="h1" v-if="quiz.korrekt">Korrekt!</span>
                            <span v-if="!quiz.korrekt" class="h1"
                              >Forkert!</span
                            >
                            <span
                              class="float-right"
                              :class="{ display: !quiz.korrekt }"
                            >
                              <i
                                class="fas fa-4x"
                                :class="{
                                  'fa-check-circle': quiz.korrekt,
                                  'fa-times-circle': !quiz.korrekt,
                                }"
                              ></i
                            ></span>
                            <div
                              class="m-1"
                              v-html="
                                modul[currentPage].quiz[quiz.quizIndex].svar[
                                  quiz.besvarelse
                                ].forklaring
                              "
                            ></div>
                            <span
                              v-show="!quiz.korrekt"
                              class="
                                click
                                mx-2
                                font-weight-bold font-italic
                                btn btn-tertiary
                              "
                              @click="resetQuiz()"
                              >Prøv igen</span
                            >
                            <span
                              v-show="
                                quiz.korrekt &&
                                this.quizBesvaret &&
                                modul[currentPage].quiz.length > 0 &&
                                quiz.quizIndex + 1 <
                                  modul[currentPage].quiz.length
                              "
                              class="
                                click
                                mx-2
                                font-weight-bold font-italic
                                btn btn-tertiary
                              "
                              @click="quiz.quizIndex++, resetQuiz()"
                              >Næste spørgsmål</span
                            >
                            <span
                              v-show="
                                quiz.korrekt &&
                                quiz.quizIndex + 1 ==
                                  modul[currentPage].quiz.length
                              "
                              class="
                                click
                                mx-2
                                font-weight-bold font-italic
                                position-absolute
                                btn btn-tertiary
                              "
                              @click="changePage()"
                              >Videre</span
                            >
                          </div>
                        </transition>
                        <div>
                          <div
                            v-for="(item, index) in modul[currentPage].quiz[
                              quiz.quizIndex
                            ].svar"
                            v-bind:key="index"
                          >
                            <p
                              type="button"
                              class="
                                px-2
                                border-left border-primary
                                mt-2
                                text-left
                              "
                              :class="{
                                tjek: item.correct,
                                forkert: !item.correct,
                              }"
                              @click="tjekSvar(index)"
                            >
                              <span v-html="item.tekst"></span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="svarboksTekst d-flex flex-column">
                    <div
                      class="
                        float-left
                        click
                        d-flex
                        align-items-baseline
                        blue-icon
                        pr-2
                      "
                      data-toggle="collapse"
                      href="#quiz"
                      aria-expanded="false"
                      aria-controls="quiz"
                      @click="quiz.collapsed = !quiz.collapsed"
                    >
                      <i
                        class="fas mx-2 fa-2x blue-icon"
                        :class="{
                          'fa-plus': quiz.collapsed,
                          'fa-minus': !quiz.collapsed,
                        }"
                      ></i>
                      <p class="h4">Quiz</p>
                    </div>
                    <div id="quiz" class="collapse">
                      <p
                        class="p-2 flex-column"
                        v-html="modul[currentPage].quiz[quiz.quizIndex].quizSpg"
                      ></p>
                      <div class="quiz position-relative">
                        <transition name="fade">
                          <div
                            v-show="this.quizBesvaret"
                            class="p-4 overlay position-absolute"
                            :class="{
                              'bg-success': quiz.korrekt,
                              error: !quiz.korrekt,
                            }"
                          >
                            <span class="h1" v-if="quiz.korrekt">Correct!</span>
                            <span v-if="!quiz.korrekt" class="h1"
                              >Incorrect!</span
                            >
                            <span
                              class="float-right"
                              :class="{ display: !quiz.korrekt }"
                            >
                              <i
                                class="fas fa-4x"
                                :class="{
                                  'fa-check-circle': quiz.korrekt,
                                  'fa-times-circle': !quiz.korrekt,
                                }"
                              ></i
                            ></span>
                            <div
                              class="m-1"
                              v-html="
                                modul[currentPage].quiz[quiz.quizIndex].svar[
                                  quiz.besvarelse
                                ].forklaring
                              "
                            ></div>
                            <span
                              v-show="!quiz.korrekt"
                              class="
                                click
                                mx-2
                                font-weight-bold font-italic
                                btn btn-tertiary
                              "
                              @click="resetQuiz()"
                              >Try again</span
                            >
                            <span
                              v-show="
                                quiz.korrekt &&
                                this.quizBesvaret &&
                                modul[currentPage].quiz.length > 0 &&
                                quiz.quizIndex + 1 <
                                  modul[currentPage].quiz.length
                              "
                              class="
                                click
                                mx-2
                                font-weight-bold font-italic
                                btn btn-tertiary
                              "
                              @click="quiz.quizIndex++, resetQuiz()"
                              >Next question</span
                            >
                            <span
                              v-show="
                                quiz.korrekt &&
                                quiz.quizIndex + 1 ==
                                  modul[currentPage].quiz.length
                              "
                              class="
                                click
                                mx-2
                                font-weight-bold font-italic
                                position-absolute
                                btn btn-tertiary
                              "
                              @click="changePage()"
                              >Continue</span
                            >
                          </div>
                        </transition>
                        <div>
                          <div
                            v-for="(item, index) in modul[currentPage].quiz[
                              quiz.quizIndex
                            ].svar"
                            v-bind:key="index"
                          >
                            <p
                              type="button"
                              class="
                                px-2
                                border-left border-primary
                                mt-2
                                text-left
                              "
                              :class="{
                                tjek: item.correct,
                                forkert: !item.correct,
                              }"
                              @click="tjekSvar(index)"
                            >
                              <span v-html="item.tekst"></span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="modul[currentPage].wordClick">
                  <div class="svarboksTekst d-flex flex-column">
                    <div
                      class="
                        float-left
                        click
                        d-flex
                        align-items-baseline
                        blue-icon
                        pr-2
                      "
                      data-toggle="collapse"
                      href="#wordclick"
                      aria-expanded="false"
                      aria-controls="tips"
                      @click="collapsed = !collapsed"
                    >
                      <i
                        class="fas mx-2 fa-2x blue-icon"
                        :class="{
                          'fa-plus': collapsed,
                          'fa-minus': !collapsed,
                        }"
                      ></i
                      ><span class="h4">Quiz</span>
                    </div>
                    <div id="wordclick" class="collapse svarboks">
                      <div class="p-2 d-flex flex-wrap">
                        <p>
                          Klik på de ord, du mener indeholder fejl og tjek om du
                          fangede dem alle!
                        </p>
                        <div class="border d-flex flex-wrap border-primary">
                          <span
                            v-for="(item, index) in wordClick.ord"
                            @click="vaelg(index)"
                            :class="wordClick.click"
                            :key="item"
                          >
                            <span
                              :class="{
                                korrektValg:
                                  quizBesvaret &&
                                  wordClick.svarArray.includes(index) &&
                                  tjekOmValgt(index),
                                forkertvalg:
                                  quizBesvaret &&
                                  !wordClick.svarArray.includes(index) &&
                                  tjekOmValgt(index),
                                valgt: tjekOmValgt(index),
                                'px-1': !tjekOmValgt(index),
                              }"
                            >
                              <span v-if="item.fejl && quizBesvaret"
                                ><strong>{{ item.korrekt }}</strong></span
                              ><span v-else>{{ item.txt }}</span></span
                            >
                          </span>
                        </div>
                        <div class="w-100 d-flex justify-content-center">
                          <button
                            v-if="!quizBesvaret"
                            type="button"
                            class="btn btn-tertiary mx-2 m-2 w-50"
                            @click="visSvar()"
                          >
                            Tjek svar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-100 center bottomBar"
            data-toggle="modal"
            v-bind:data-target="modal"
          >
            <div class="svarboksTekst mx-auto text-right">
              <button
                class="button txt"
                v-bind:class="{ marginAdjustRight: currentPage != 0 }"
                @click="changePage()"
              >
                <span v-if="danish">NÆSTE </span><span v-else>NEXT </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Valgmodal -->
    <div
      class="modal fade"
      id="valgModal"
      tabindex="1"
      role="dialog"
      aria-labelledby="valgModal"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content mx-auto">
          <div class="modal-header bg-blue">
            <div class="modal-title">
              <h1
                id="titelSporgsmaal"
                class="pt-2"
                v-html="modul[currentPage].titelSporgsmaal"
              ></h1>
            </div>
            <i
              v-if="danish"
              class="fas fa-question-circle float-right fa-4x px-3 click c-nav"
              :class="{ 'd-none': !visSvarTekst }"
              @click="visSvarTekst = false"
              title="Tilbage - vælg et andet svar"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-original-title="Tilbage - vælg et andet svar"
            ></i>
            <i
              v-else
              class="fas fa-question-circle float-right fa-4x px-3 click c-nav"
              :class="{ 'd-none': !visSvarTekst }"
              @click="visSvarTekst = false"
              title="Go back - choose another answer"
              data-original-title="To back - choose another answer"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
            ></i>
          </div>
          <div class="modal-body">
            <div v-if="visSvarTekst" class="bg-blue">
              <video
                v-if="modul[currentPage].svarVideo"
                autoplay
                controls
                id="svarvideo"
                class="w-100"
              >
                <source
                  :src="modul[currentPage].svarVideo[svar]"
                  type="video/mp4"
                />
                <track
                  v-if="danish"
                  :src="modul[currentPage].subtitles[svar]"
                  kind="subtitles"
                  srclang="da"
                  label="Danish"
                  default
                />
                <track
                  v-else
                  :src="modul[currentPage].subtitles[svar]"
                  kind="subtitles"
                  srclang="en"
                  label="English"
                  default
                />
              </video>
              <div
                v-else
                v-html="modul[currentPage].text[svar]"
                class="svarboks p-4"
              ></div>
              <div
                class="w-100 center bottomBar"
                data-toggle="modal"
                v-bind:data-target="modal"
              >
                <div v-if="visSvarTekst" class="text-right">
                  <button
                    class="button txt"
                    @click="showVideoTips()"
                    data-dismiss="modal"
                  >
                    <span v-if="danish">VIDERE</span
                    ><span v-else style="font-size: 0.6rem">CONTINUE</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-else class="flex-column" id="sporgsmaalListe">
              <div
                v-for="(item, index) in modul[currentPage].sporgsmaal"
                v-bind:key="item"
              >
                <div class="p-1">
                  <button
                    type="button"
                    class="btn text-left w-100"
                    @click="chooseAnswer(index, item)"
                  >
                    <!-- <span class=""> -->
                    <span class="fa-stack">
                      <span
                        class="fas fa-circle blue-icon fa-2x fa-stack-2x"
                      ></span>
                      <strong class="fa-stack-1x" style="color: white">
                        {{ index + 1 }}
                      </strong>
                    </span>
                    <span class="mx-2 svar align-middle">{{ item }}</span>
                    <!-- </span> -->
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modul Vælger -->
    <div
      class="modal fade"
      id="breakerModal"
      tabindex="1"
      role="dialog"
      aria-labelledby="breakerModal"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md" role="document">
        <!--***-->
        <div class="modal-content">
          <div class="modal-header" style="background-color: #5eafbc">
            <h1 class="modal-title">
              <span v-if="danish">Studie&shy;viden</span
              ><span v-else>Study Skills</span>
              <transition name="fadesmiley">
                <span v-if="elementVisible"
                  ><span v-if="danish" class="diy">
                    / Test dig selv
                    <!-- <i class="fab fa-earlybirds fa-beat"></i> -->
                  </span><span v-else class="diy">
                    / Test your skills
                    <!-- <i class="fab fa-earlybirds fa-beat"></i> -->
                  </span></span
                >
                <!-- 😎 -->
              </transition>
            </h1>
            <div>
              <span
                v-if="danish"
                class="languageIcon english float-right mx-1"
                v-on:click="languageSelect"
              ></span>
              <span
                v-else
                class="languageIcon danish float-right mx-1"
                v-on:click="languageSelect"
              ></span
              ><br />
              <i
                type="button"
                data-toggle="modal"
                data-target="#infoModal"
                class="faButton mx-0 fas fa-info-circle fa-2x click float-right"
              ></i>
            </div>
          </div>
          <div class="modal-body">
            <div>
              <div
                v-if="menu.completed.length == 0"
                v-on:click="videoClicked($event.target)"
              >
                <!-- <audio autoplay>
                  <source src="./audio/car.m4a" type="audio/mp4">
                  Your browser does not support the audio element.
                </audio> -->
                <video
                  v-if="danish"
                  id="introvideo"
                  preload="none"
                  v-on:timeupdate="btnFadeIn()"
                  class="w-100 h-100"
                  poster="/video/forside_poster.png"
                >
                  <source src="/video/selvstudie_intro.mp4" type="video/mp4" />
                  <track
                    src="/video/selvstudie_intro.vtt"
                    kind="subtitles"
                    srclang="da"
                    label="Danish"
                    default
                  />
                </video>
                <video
                  v-else
                  id="introvideo"
                  preload="none"
                  v-on:timeupdate="btnFadeIn()"
                  class="w-100 h-100"
                  poster="/video/forside_poster_en.png"
                >
                  <source src="/video/selvstudie_intro.mp4" type="video/mp4" />
                  <track
                    src="/video/selvstudie_intro_en.vtt"
                    kind="subtitles"
                    srclang="da"
                    label="Danish"
                    default
                  />
                </video>
                <i
                  v-if="videoPlayButton && modul[currentPage].type != 'youtube'"
                  class="fas fa-play-circle video-play-button-intro fa-4x"
                  aria-hidden="true"
                ></i>
              </div>
              <div v-if="menu.completed.length > 0">
                <video
                  v-on:timeupdate="checkVideoTime('#outro')"
                  id="outro"
                  controls
                  class="w-100 h-100"
                  poster="/grafik/logo_main_poster.png"
                  preload="none"
                >
                  <source
                    :src="menu.links[menu.selectedModulIndex].outroVideo"
                    type="video/mp4"
                  />
                  <track
                    :src="menu.links[menu.selectedModulIndex].outroVideoVtt"
                    kind="subtitles"
                    srclang="da"
                    label="Danish"
                    default
                  />
                </video>
              </div>
              <menu-component
                v-bind:menu="menu"
                v-bind:specific-module="specificModule"
                v-bind:selected-modul="selectedModul"
              >
              </menu-component>
            </div>
          </div>
        </div>
        <div
          id="btn_Praesentation"
          class="modal-content text-center d-flex justify-content-center"
          data-dismiss="modal"
          @click="specificModule('Praesentation', 3)"
        >
          <i class="far fa-2x fa-comment m-2"></i>
          <h2 v-if="danish">
            <span class="red">Bonusmodul:</span>
            Præsentationsteknik
          </h2>
          <h2 v-else>
            <span class="red">Bonus module:</span>
            Presentation Technique
          </h2>
        </div>
      </div>
    </div>

    <!-- Image modal -->
    <div
      class="modal fade"
      id="imageModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog mw-100 w-75" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <img src="" alt="" id="modalImage" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>

    <!-- Info modal -->
    <div
      class="modal fade"
      id="infoModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header" style="background-color: #f6cb3c">
            <h1 v-if="danish" class="modal-title">Om modulet</h1>
            <h1 v-else class="modal-title">About the module</h1>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="danish" class="p-4">
              <p>
                Selvstudiemodulet er et interaktivt studieværktøj, der
                understøtter de studerendes studiekompetencer inden for:
              </p>
              <ol>
                <li>Arbejdsproces i forbindelse med projektskrivning</li>
                <li>Projektskrivningen</li>
                <li>Den mundtlige eksamen og nervøsitet</li>
              </ol>
              <p>
                I modulet skal den studerende ud fra en række spørgsmål
                reflektere over egen studiepraksis, og tage stilling til, hvor
                stærk vedkommende er i forhold til arbejdsproces,
                projektskrivning og mundtlig eksamen. Afhængig af sit svar
                modtager den studerende forskellige råd og vejledning. Det er
                således den studerende selv, der bestemmer vejen og guider
                rejsen gennem selvstudiemodulet.
              </p>
              <p>
                Tidsmæssigt kan modulet variere meget, da det netop er den
                studerendes svar, der afgører med hvilken dybde og hurtighed
                modulet bevæger sig. Ligesom det heller ikke er nødvendigt at
                genneføre alle tre emner på én gang.
              </p>
              <p>
                Modulet henvender sig til alle studerende, der skal igang med en
                større opgave, og er designet således, at det kan rumme både
                dem, der planlægger alt ned til mindste detalje, og dem, som
                måske er lidt mere laissez faire anlagt.
              </p>
              <p>
                Formmæssigt er modulet bygget op omkring video, tekst og quiz.
              </p>
              <p>
                For yderligere information kontakt:
                <a href="mailto:heg@kea.dk">Biblioteksleder Helle Guldberg</a>
              </p>
              <p>Rigtig god fornøjelse!</p>
            </div>
            <div v-else class="p-4">
              <p>
                The self-study module is an interactive study tool that supports
                students' study competencies within:
              </p>
              <ol>
                <li>Workflow in Project Writing</li>
                <li>Project Writing</li>
                <li>The oral exam and nervousness</li>
              </ol>
              <p>
                In this module, the student is asked to answer a number of
                questions, to reflect on her study practice, and self-evaluate
                on how strong she is in relation to work process, project
                writing and oral examination. Depending on the answer, the
                student receives various advice and guidance. It is the student
                herself, who decides the path through the self-study module.
              </p>
              <p>
                In terms of duration, the module can vary greatly, as it is the
                student's answer that determines the depth and speed of the
                module. It is not necessary to dive into all three topics at
                once.
              </p>
              <p>
                The module is targeted at all students who are starting on a
                major assignment and is designed so that it can accommodate both
                those who plan everything down to the smallest detail and those
                who may be a little more laissez faire.
              </p>
              <p>
                In terms of form, the module is built around video, text and
                quizzes.
              </p>
              <p>
                For further information contact:
                <a href="mailto:heg@kea.dk"> Library Manager Helle Guldberg </a>
              </p>
              <p>Have fun!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-env jquery */
import MenuComponent from "./components/MenuComponent.vue";

import * as data from "./data.js";
const quiz = data.quiz;
const wordClick = data.wordClick;
const projekt = data.projekt;
const eksamen = data.eksamen;
const arbejdsproces = data.arbejdsproces;
const praesentationsteknik = data.praesentationsteknik;
const menu = data.menu;
const audio = new Audio("");

export default {
  name: "App",
  components: {
    MenuComponent,
  },
  data() {
    return {
      danish: true,
      modal: null,
      elementVisible: false,
      question: true,
      currentPage: "0",
      svar: "0",
      visSvarTekst: false,
      selectedTip: "0",
      collapsed: true,
      modalImage: "",
      quizBesvaret: false,
      selectedModul: "Projekt", //projekt, arbejdsproces, eksamen
      videoPlayButton: true,

      answered: false,
      menu: menu,
      projekt: projekt,
      arbejdsproces: arbejdsproces,
      eksamen: eksamen,
      praesentationsteknik: praesentationsteknik,
      wordClick: wordClick,
      quiz: quiz,
    };
  },
  created() {
    this.setStoredLanguage();
  },
  mounted() {
    if (Number(localStorage.currentPage) > 0) {
      this.selectedModul = localStorage.getItem("modul");
      this.svar = localStorage.getItem("svar");
      menu.completed = JSON.parse(localStorage.completed);
      this.currentPage = Number(localStorage.currentPage);
      this.answered = true;
    } else {
      this.triggerBreakModal();
    }
    setTimeout(() => (this.elementVisible = true), 2000);
  },
  updated() {
    $(".c-nav").tooltip();
  },
  computed: {
    youtubePosterImage() {
      return this.modul[this.currentPage].poster;
    },
    tips() {
      return this.modul[this.currentPage].tips[this.selectedTip].text;
    },
    modul() {
      switch (this.selectedModul) {
        case "Projekt":
          return this.projekt;
        case "Arbejdsproces":
          return this.arbejdsproces;
        case "Eksamen":
          return this.eksamen;
        case "Praesentation":
          return this.praesentationsteknik;
        case "Projekt_en":
          return this.projekt_en;
        case "Arbejdsproces_en":
          return this.arbejdsproces_en;
        case "Eksamen_en":
          return this.eksamen_en;
        case "Praesentation_en":
          return this.praesentationsteknik_en;
        default:
          return "";
      }
    },
  },
  methods: {
    youtubePlay() {
      $("#video").show();
      this.modul[this.currentPage].video =
        this.modul[this.currentPage].video + "?autoplay=1";
    },
    videoClicked(target) {
      if (
        target.nextSibling.className ==
          "fas fa-play-circle video-play-button fa-4x" ||
        target.nextSibling.className ==
          "fas fa-play-circle video-play-button-intro fa-4x"
      ) {
        if (this.modul[this.currentPage].type != "youtube") {
          target.play();
          target.controls = true;
        }
        target.nextSibling.classList.remove(
          "video-play-button",
          "video-play-button-intro"
        );
        this.videoPlayButton = false;
      }
    },
    setStoredLanguage() {
      if (localStorage.getItem("language") == "danish") {
        this.danish = true;
      } else if (localStorage.getItem("language") == "english") {
        this.danish = false;
      }
      this.changeLanguage();
    },
    languageSelect() {
      this.danish = !this.danish;
      this.changeLanguage();
    },
    changeLanguage() {
      if (this.danish) {
        this.quiz = data.quiz;
        this.wordClick = data.wordClick;
        this.projekt = data.projekt;
        this.eksamen = data.eksamen;
        this.arbejdsproces = data.arbejdsproces;
        this.praesentationsteknik = data.praesentationsteknik;
        this.menu = data.menu;
        localStorage.setItem("language", "danish");
      } else {
        // this.quiz = data.quiz_en;
        // this.wordClick = data.wordClick_en;
        this.projekt = data.projekt_en;
        this.eksamen = data.eksamen_en;
        this.arbejdsproces = data.arbejdsproces_en;
        this.praesentationsteknik = data.praesentationsteknik_en;
        this.menu = data.menu_en;
        localStorage.setItem("language", "english");
      }
    },
    checkVideoTime: function (id) {
      if ($(id)[0] !== undefined) {
        if ($(id)[0].ended) {
          $(id)[0].load();
          $(id).attr("poster", "./grafik/logo_main_poster.png");
        }
      }
    },

    btnFadeIn() {
      let introvideo = $("#introvideo")[0];
      if (introvideo != undefined) {
        if (introvideo.currentTime > 5) {
          $("#btn_Projekt").removeClass("hidden");
          $("#btn_Projekt").addClass("fadeIn");
        }
        if (introvideo.currentTime > 6) {
          $("#btn_Eksamen").removeClass("hidden");
          $("#btn_Eksamen").addClass("fadeIn");
        }
        if (introvideo.currentTime > 7) {
          $("#btn_Arbejdsproces").removeClass("hidden");
          $("#btn_Arbejdsproces").addClass("fadeIn");
        }
        if (introvideo.currentTime > 30) {
          // $("#btn_Praesentation").removeClass("hidden");
          $("#btn_Praesentation").addClass("pulse");
          // $("#btn_Praesentation").css("visibility", "visible");
          // $('#btn_Praesentation').attr('style', 'display: block !important');
        }
        if (introvideo.ended) {
          $("#introvideo")[0].load();
          $("#introvideo").attr("poster", "./grafik/logo_main_poster.png");
        }
      }
    },
    showVideoTips: function () {
      this.answered = true;
      this.visSvarTekst = false;
      $("#valgModal").modal("hide");
    },
    chooseAnswer: function (i, value) {
      //special case for at gå direkte til afslutning ved sidste spørgsmål i gruppearbejde
      if (
        value == "Jeg arbejder aldrig i grupper, hvis jeg kan undgå det" ||
        value == "Niks, jeg skal ikke til online-eksamen" ||
        value == "Nope, I'm not taking an online exam" ||
        value == "I never work in groups if I can avoid it"
      ) {
        this.currentPage++;
        localStorage.setItem("completed", JSON.stringify(menu.completed));
        this.showVideoTips();
        this.triggerBreakModal();
      } else {
        // this.answered = true;
        /* audio.src = this.modul[this.currentPage].audio[i];
        audio.play(); */
        localStorage.setItem("svar", i);
        this.visSvarTekst = true;
        this.svar = i;
      }
    },
    nextPage: function () {
      this.videoPlayButton = true;
      // menu.seMere = true;
      this.currentPage++;
      quiz.quizIndex = 0;
      this.collapsed = true;
      this.resetQuiz();
      localStorage.setItem("currentPage", this.currentPage);
      localStorage.setItem("completed", JSON.stringify(menu.completed));
      if (this.currentPage < this.modul.length) {
        // hvis sidste side i indholds array launch breakerModal
        this.triggerValgModal();
      } else {
        localStorage.setItem("currentPage", Number(this.modul.length - 1));
        this.triggerBreakModal();
      }
    },
    triggerValgModal: function () {
      this.answered = false;
      // menu.seMere = true;
      this.menu.seMere = true;
      $(".tooltip").hide();
      $("#valgModal").modal().show();
    },
    triggerBreakModal: function () {
      this.videoPlayButton = true;
      let page = this.currentPage;
      this.answered = false;
      this.currentPage = 0;
      localStorage.setItem("currentPage", this.currentPage);
      $("#breakerModal").modal().show();
      //bruger kun play på outrovideosiden for at undgå fejl (den er ikke klar til play() da vi ikke er på outro siden)
      if (page == this.modul.length) {
        $("#outro").trigger("play");
        $("#btn_" + this.selectedModul).addClass("completed");
      }
    },
    changePage: function () {
      audio.pause();
      this.nextPage();
    },
    specificModule: function (i, index) {
      this.selectedModul = i;
      this.menu.selectedModulIndex = index;
      this.menu.completed.push(i);
      this.answered = true;
      localStorage.setItem("modul", this.selectedModul);
      localStorage.setItem("completed", JSON.stringify(menu.completed));
      $("#outro").attr("src", ""); //stopper video playback
      let video = this.menu.links[this.menu.selectedModulIndex].outroVideo;
      $("#outro").attr("src", video);
      $("#btn_Projekt").removeClass("hidden");
      $("#btn_Eksamen").removeClass("hidden");
      $("#btn_Arbejdsproces").removeClass("hidden");
      $("#btn_Praesentation").removeClass("hidden");
    },
    specificPage: function (i) {
      this.videoPlayButton = true;
      this.currentPage = i;
      this.triggerValgModal();
    },
    strippedTitle(value) {
      let title = value.replace("&shy;", "");
      return title;
    },
    changeTip: function (i) {
      return (this.selectedTip = i);
    },

    toggleAudio: function () {
      $("#audioButton").toggleClass("fa-pause-circle fa-play-circle");
      return audio.paused ? audio.play() : audio.pause();
    },

    resetQuiz() {
      this.quizBesvaret = false;
      quiz.collapsed = true;
      quiz.besvarelse = 0;
      $(".tjek").removeClass("btn-success").prop("disabled", false); //fjerner visning af korrekt svar
      $(".forkert").removeClass("btn-success").prop("disabled", false); //fjerner visning af korrekt svar
    },
    korrektSvar: function () {
      // returnerer det index der indeholder det rigtige svar
      for (
        var i = 0;
        i < this.modul[this.currentPage].quiz[quiz.quizIndex].svar.length;
        i++
      ) {
        if (this.modul[this.currentPage].quiz[quiz.quizIndex].svar[i].correct) {
          return i;
        }
      }
    },
    tjekSvar: function (index) {
      quiz.besvaret = true;
      quiz.besvarelse = index;
      this.quizBesvaret = true;
      // $('.tjek').addClass('btn-success').prop('disabled', true); //viser korrekte svar
      $("#quizModal").find("button").prop("disabled", true); //disable knapper
      if (this.korrektSvar(index) == index) {
        quiz.korrekt = true;
      } else {
        // $('.forkert').addClass('btn-danger').prop('disabled', true);
        quiz.korrekt = false;
        // $('#quizModal').find('.modal-header').addClass('bg-danger');
      }
    },
    tjekOmValgt(indexNr) {
      if (this.wordClick.selectedWords.words.some((e) => e.id === indexNr)) {
        return true;
      }
    },
    vaelg: function (index) {
      var selectedWordsObj = {};
      selectedWordsObj["id"] = index;
      let addWord = true;
      if (wordClick.ord[index].fejl) {
        selectedWordsObj["fejl"] = true;
      }
      if (wordClick.selectedWords.words.length > 0) {
        for (var i = 0; i < wordClick.selectedWords.words.length; i++) {
          if (wordClick.selectedWords.words[i].id === selectedWordsObj["id"]) {
            addWord = false;
            wordClick.selectedWords.words.splice(i, 1); //remove selected
          }
        }
      }
      if (addWord) {
        this.wordClick.selectedWords.words.push(selectedWordsObj);
        //          this.wordClick.selectedWords.push(selectedWordsObj);
      }
    },
    genererSvarArray: function () {
      for (var i = 0; i < wordClick.ord.length; i++) {
        if (wordClick.ord[i].fejl) {
          wordClick.svarArray.push(i);
        }
      }
    },
    visSvar: function () {
      this.genererSvarArray();
      $("#wordClick").prop("disable", true);
      this.quizBesvaret = true;
    },
  },
};
</script>

<style src="@/assets/css/style.css"></style>
