<template>
  <div class="d-flex flex-column flex-lg-row justify-content-around">
    <div v-for="(link, index) in menu.links" v-bind:key="index">
      <div
        v-if="!(menu.links[index].modul == 'Praesentation')"
        class="btn p-2 d-flex flex-row flex-lg-column hidden"
        :id="'btn_' + menu.links[index].modul"
        data-dismiss="modal"
        @click="specificModule(link.modul, index)"
      >
        <span class="d-none d-lg-block" v-html="link.icon"></span>
        <h2>
          <i
            v-if="menu.completed.includes(link.modul)"
            class="far fa-check-square m-2"
          ></i
          ><i v-else class="far fa-square m-2"></i
          ><span v-html="link.titel"></span>
        </h2>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      elementVisible: false,
    };
  },
  props: {
    menu: { type: Object },
    specificModule: { type: Function },
    selectedModul: { type: String },
  },
  mounted() {
    // setTimeout(() => this.elementVisible = true, 2000)
  },
};
</script>
